import React, { Component } from 'react'
import '../App.css';
import Drawer from 'react-drag-drawer';
import '../Popupstyle.css';
import ThemeManager from '../ThemeManager';
import i18n from "i18next";

export class PhoneNumber extends Component {

    constructor(props){
        super(props);
        var mobileSize = '12px';
        if(props.phone!==''){
            mobileSize = '14px';
        }

        this.state = { mobileValue: props.phone, showMenu: false, numberSize: mobileSize, openPrefix: false, prefixValue: props.prefix, canEdit: props.canEdit, prefixDisplay: '🇮🇹 +39'};

        
       
    }

    toggle = () => {
        this.setState({ openPrefix: false})
    }
       
    showPrefixSelection = (e) => {
        if(this.state.canEdit){ 
            this.setState({ openPrefix: true});
        }
    }

    selectPrefix = (e) => {
        
            this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id});
            this.toggle();
        
    }

    insertNumber = (e) => {

        if(this.state.canEdit){ 

        var insert = String(e.target.value);
        
        if(!isNaN(insert)){
            this.setState({ mobileValue: insert});
        }

        if(e.target.value.length>0){
            this.setState({ numberSize: '14px'});
        }else{
            this.setState({ numberSize: '12px'});
        }

        }
    }

    


    confirmNumber = (e) => {
       
        if(this.state.mobileValue.length > 8 && this.state.mobileValue.length < 12 && !isNaN(this.state.mobileValue)){
            this.props.login(this.state.mobileValue,this.state.prefixValue);
        }
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>

            <div style={{marginTop: '80px'}}>
            <p style={{color: _secondaryTextColor, fontSize: '13px'}}>{i18n.t('insert.mobile')}</p>
            <p style={{marginTop: '-12px', color: _secondaryTextColor, fontSize: '13px'}}>{i18n.t('to.continue.booking')}</p>
            </div>



            <div  style={{marginTop: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _mainTextColor, marginLeft: '0px',
             fontSize: '14px', fontWeight: 'thin'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '30px', border: 'none', color: _mainTextColor, fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, outlineWidth: '0', fontSize: this.state.numberSize}} type="tel"  
                name="mobile"
                value={this.state.mobileValue}
                placeholder={i18n.t('mobile.number')}
                onChange={this.insertNumber}
                />
                </div>
                </div>
                </div>

                
           
               
                <div  style={{marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmNumber}
            >
                    {i18n.t('next')}</p>
                    </div>


                    <Drawer
                    open={this.state.openPrefix}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>
<div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="52">🇲🇽 +52</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="385">🇭🇷 +385</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="386">🇸🇮 +386</p>

</div>

<div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="212">🇲🇦 +212</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="216">🇹🇳 +216</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="213">🇩🇿 +213</p>

</div>

                    </div>
                    </Drawer>

            </div>
        )
    }
}

/*

                onFocus={this.onFocusInput}
                onBlur={this.outOfFocusInput}
                onChange={this.onChangeInput}
*/

export default PhoneNumber
